
import { FormQcLogin } from "./FormQcLogin";
import QcLoginLayout from "./QcLoginLayout";
function Qclogin() {
  return (
    <>
      <QcLoginLayout/>
      <FormQcLogin />
    </>
  );
}

export default  Qclogin